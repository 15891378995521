import JwtService from "@/core/services/JwtService";
import { AuthModule } from "@/store/modules/auth";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { App } from "vue";
import VueAxios from "vue-axios";
import { getModule } from "vuex-module-decorators";

interface FailedRequest {
  resolve: (value: unknown) => void;
  reject: (value: unknown) => void;
}

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  public static isRefreshing = false;
  public static failedQueue: FailedRequest[] = [];

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.accessToken = JwtService.getToken() ?? "";
  }
  private static accessToken: string;

  public static updateAccessToken(token: string) {
    ApiService.accessToken = token;
  }

  public static setupInterceptors(store) {
    const authModule = getModule(AuthModule, store);

    const includeRequest = ["/usuarios", "/familia", "/familia/documentos"];
    // Inyecta el token en las cabeceras
    ApiService.vueInstance.axios.interceptors.request.use(
      (config) => {
        const token = this.accessToken;
        if (
          token &&
          includeRequest.some((path) => config.url?.includes(path))
        ) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const ignoredRequest = ["/usuarios/login", "/usuarios/token-refresh"];
    // Se encarga de actualizar el token si ha caducado
    ApiService.vueInstance.axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;

        if (!ignoredRequest.includes(originalConfig.url) && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            if (ApiService.isRefreshing) {
              return new Promise(function (resolve, reject) {
                ApiService.failedQueue.push({ resolve, reject });
              })
                .then((token) => {
                  originalConfig.headers["Authorization"] = "Bearer " + token;
                  return axios(originalConfig);
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }

            originalConfig._retry = true;
            ApiService.isRefreshing = true;

            try {
              const rs = await ApiService.vueInstance.axios
                .post("/usuarios/token-refresh", {
                  refreshToken: JwtService.getRefreshToken(),
                })
                .catch((err) => {
                  if ([404, 401].includes(err?.response?.status)) {
                    throw new Error("TOKEN_EXPIRED");
                  }
                  throw err;
                });
              const data = rs.data;

              authModule.refreshToken(data);
              ApiService.updateAccessToken(data.accessToken);
              ApiService.processQueue(null, data.accessToken);
              return ApiService.vueInstance.axios(originalConfig);
            } catch (_error) {
              ApiService.processQueue(_error, null);

              if (
                axios.isAxiosError(_error) &&
                _error?.message === "TOKEN_EXPIRED"
              ) {
                authModule.logout();
              }

              return Promise.reject(_error);
            } finally {
              ApiService.isRefreshing = false;
            }
          }
        }

        return Promise.reject(err);
      }
    );
  }

  public static processQueue(error, token = null) {
    ApiService.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    ApiService.failedQueue = [];
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`, config);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, data, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params, config);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  public static handleError(err: Error | AxiosError) {
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.status === 422) {
        throw err.response.data;
      } else if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        const axiosError = { error: err };
        throw axiosError;
      }
    } else {
      const generalError = { error: err };
      throw generalError;
    }
  }
}

export default ApiService;
