import $ from "jquery";

export function initi18nes() {
  $.fn.select2.defaults.set("language", {
    // You can find all of the options in the language files provided in the
    // build. They all must be functions that return the string that should be
    // displayed.
    removeItem: function () {
      return "Eliminar";
    },
    errorLoading: function () {
      return "No se pudieron cargar los resultados";
    },
    inputTooLong: function (e) {
      const n = e.input.length - e.maximum;
      let r = "Por favor, elimine " + n + " car";
      return (r += 1 == n ? "ácter" : "acteres");
    },
    inputTooShort: function (e) {
      const n = e.minimum - e.input.length;
      let r = "Por favor, introduzca " + n + " car";
      return (r += 1 == n ? "ácter" : "acteres");
    },
    loadingMore: function () {
      return "Cargando más resultados…";
    },
    maximumSelected: function (e) {
      let n = "Sólo puede seleccionar " + e.maximum + " elemento";
      return 1 != e.maximum && (n += "s"), n;
    },
    noResults: function () {
      return "No se encontraron resultados";
    },
    searching: function () {
      return "Buscando…";
    },
    removeAllItems: function () {
      return "Eliminar todos los elementos";
    },
  });
}
