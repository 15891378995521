enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  CHECK_FAMILIAS = "checkFamilias",
  POST_FAMILIAS = "postFamilias",
  POST_ACTIVIDAD = "postActividad",
  POST_PROFESIONALES = "postProfesionales",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  CHECK_FAMILIAS_PENDING = "checkFamiliasPending",
  CHECK_FAMILIAS_SUCCESS = "checkFamiliasSuccess",
  CHECK_FAMILIAS_FAILURE = "checkFamiliasFailure",
  POST_FAMILIAS_PENDING = "postFamiliasPending",
  POST_FAMILIAS_SUCCESS = "postFamiliasSuccess",
  POST_FAMILIAS_FAILURE = "postFamiliasFailure",
  POST_ACTIVIDAD_PENDING = "postActividadPending",
  POST_ACTIVIDAD_SUCCESS = "postActividadSuccess",
  POST_ACTIVIDAD_FAILURE = "postActividadFailure",
  POST_PROFESIONALES_PENDING = "postProfesionalesPending",
  POST_PROFESIONALES_SUCCESS = "postProfesionalesSuccess",
  POST_PROFESIONALES_FAILURE = "postProfesionalesFailure",
}

export { Actions, Mutations };
