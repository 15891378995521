import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ServicioProgramado } from "../models";

@Module({ namespaced: true, name: "EncuestaModule" })
export default class EncuestaModule extends VuexModule {
  servicio: ServicioProgramado | null = null;
  isLoading = false;

  @Action({ rawError: true })
  fetchServiciosProgramado({
    servicioId,
    identificador,
  }: {
    servicioId: string;
    identificador: string;
  }) {
    this.setLoad(true);

    const url = `/encuestas/servicios-programados/${servicioId}`;
    return ApiService.get(url, { params: { identificador } })
      .then(({ data }) => {
        this.setServicio(data);
        this.setLoad(false);
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            throw err.response.data;
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            const axiosError = { error: err };
            throw axiosError;
          }
        } else {
          const generalError = { error: err };
          throw generalError;
        }
      });
  }

  @Action({ rawError: true })
  responderServiciosProgramado({
    servicioId,
    identificador,
    puntuacion,
    comentario,
  }: {
    servicioId: string;
    identificador: string;
    puntuacion: number;
    comentario: string;
  }) {
    const url = `/encuestas/servicios-programados/${servicioId}/respuesta`;
    return ApiService.post(
      url,
      { puntuacion, comentario },
      { params: { identificador } }
    ).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          throw err.response.data;
        } else if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          const axiosError = { error: err };
          throw axiosError;
        }
      } else {
        const generalError = { error: err };
        throw generalError;
      }
    });
  }

  @Mutation
  setServicio(data) {
    this.servicio = data;
  }

  @Mutation
  setLoad(isLoading) {
    this.isLoading = isLoading;
  }
}
