import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-column-fluid" }
const _hoisted_2 = { class: "d-flex flex-column flex-column-fluid text-center p-10 py-lg-15" }
const _hoisted_3 = { class: "pt-lg-10 mb-10" }
const _hoisted_4 = { class: "fw-bolder fs-4x text-gray-800 mb-10" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("errorSistema")), 1),
        _createElementVNode("div", {
          class: "fw-bold fs-3 text-muted mb-15",
          innerHTML: _ctx.$t('algoFueMal')
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            to: "/home",
            class: "btn btn-lg btn-primary fw-bolder"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("volverInicio")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", {
        class: "d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px",
        style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.getIllustrationsPath('17.png') + ')',
        })
      }, null, 4)
    ])
  ]))
}