import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BackendError, NuevoMenor, Servicio } from "../models";
import { Documento } from "../models/Documento";
import { Familia, FamiliaEdicion } from "../models/index";

@Module({ namespaced: true, name: "FamiliasModule" })
export default class FamiliasModule extends VuexModule {
  errors: BackendError | null = null;
  familias: Familia[] = [];
  familia: Familia | null = JSON.parse(JwtService.getFamilia() ?? "null");
  servicios: Servicio[] = [];
  menores: NuevoMenor[] = [];
  documentos: Documento[] = [];
  isLoading = false;

  @Action({ rawError: true })
  fetch() {
    this.setLoad(true);

    const url = `/familia`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setDetailData(data);
        this.setLoad(false);
        JwtService.saveFamilia(data);
      })
      .catch(ApiService.handleError);
  }
  @Action
  fetchFamiliaDocumentos() {
    this.setLoad(true);

    const url = `/familia/documentos`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setDocumentosData(data);
        this.setLoad(false);
      })
      .catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  fetchMenores() {
    const url = `/familia/menores`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setMenores(data);
        return data;
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            throw err.response.data;
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            const axiosError = { error: err };
            throw axiosError;
          }
        } else {
          const generalError = { error: err };
          throw generalError;
        }
      });
  }
  @Action({ rawError: true })
  addMenor(menor: NuevoMenor) {
    const url = `/familia/menores`;

    return ApiService.post(url, menor).catch(ApiService.handleError);
  }
  @Action({ rawError: true })
  updateMenor(menor: NuevoMenor) {
    const url = `/familia/menores/${menor.id}`;

    return ApiService.put(url, menor).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  fetchServicios() {
    const url = `/familia/servicios`;
    return ApiService.get(url)
      .then(({ data }) => {
        this.setServicios(data);
        return data;
      })
      .catch((err: Error | AxiosError) => {
        this.setLoad(false);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            throw err.response.data;
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            const axiosError = { error: err };
            throw axiosError;
          }
        } else {
          const generalError = { error: err };
          throw generalError;
        }
      });
  }

  @Action({ rawError: true })
  update(update: Partial<FamiliaEdicion>) {
    const url = "/familia";

    return ApiService.put(url, update).catch(ApiService.handleError);
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.familias = data;
  }

  @Mutation
  setDetailData(data) {
    this.familia = data;
  }
  @Mutation
  setDocumentosData(data) {
    this.documentos = data;
  }
  @Mutation
  setServicios(data) {
    this.servicios = data;
  }
  @Mutation
  setMenores(data) {
    this.menores = data;
  }

  @Mutation
  setLoad(isLoading) {
    this.isLoading = isLoading;
  }

  @Action
  familiaClear() {
    this.setDetailData(null);
  }
}
