import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "../enums/StoreEnums";

export interface FormularioInfo {
  pending: boolean;
  errors: unknown;
  codigo: string;
}

@Module
export default class FormulariosModule
  extends VuexModule
  implements FormularioInfo
{
  pending = false;
  errors = {};
  codigo = "";

  get isPendingForm(): boolean {
    return this.pending;
  }

  get getCodigo(): string {
    return this.codigo;
  }

  get getError() {
    return this.errors;
  }

  @Mutation
  [Mutations.CHECK_FAMILIAS_PENDING]() {
    this.pending = true;
  }

  @Mutation
  [Mutations.CHECK_FAMILIAS_SUCCESS]() {
    this.pending = false;
  }

  @Mutation
  [Mutations.CHECK_FAMILIAS_FAILURE](errors) {
    this.errors = errors;
    this.pending = false;
  }

  @Mutation
  [Mutations.POST_FAMILIAS_PENDING]() {
    this.pending = true;
  }

  @Mutation
  [Mutations.POST_FAMILIAS_SUCCESS](data) {
    this.codigo = data.codigo;
    this.pending = false;
  }

  @Mutation
  [Mutations.POST_FAMILIAS_FAILURE](errors) {
    this.errors = errors;
    this.pending = false;
  }

  @Mutation
  [Mutations.POST_ACTIVIDAD_PENDING]() {
    this.pending = true;
  }

  @Mutation
  [Mutations.POST_ACTIVIDAD_SUCCESS](data) {
    this.codigo = data.codigo;
    this.pending = false;
  }

  @Mutation
  [Mutations.POST_ACTIVIDAD_FAILURE](errors) {
    this.errors = errors;
    this.pending = false;
  }

  @Action({ rawError: true })
  [Actions.POST_FAMILIAS](formData) {
    this.context.commit(Mutations.POST_FAMILIAS_PENDING);

    const params = {
      dni: formData.dni,
      datosSolicitud: { ...formData, libroFamilia: null },
      datosAdjuntos: {
        libroFamilia: formData.libroFamilia,
      },
      tipo: "FAMILIA",
      version: 1,
      comarcaId: formData.comarca,
    };

    return ApiService.post("peticion-formularios", params)
      .then(({ data }) => {
        this.context.commit(Mutations.POST_FAMILIAS_SUCCESS, data);
        return data.codigo;
      })
      .catch(({ response }) => {
        const error = response ? response.data.error : {};
        this.context.commit(Mutations.POST_FAMILIAS_FAILURE, error);
        throw error;
      });
  }

  @Action({ rawError: true })
  [Actions.CHECK_FAMILIAS](formData) {
    this.context.commit(Mutations.CHECK_FAMILIAS_PENDING);

    const params = {
      dni: formData.dni,
      telefono: formData.telefono,
      tipo: "FAMILIA",
      version: 1,
      comarcaId: formData.comarca,
    };
    // Se llama validar2 por el cambioen el formato de la respuesta
    return ApiService.post("peticion-formularios/familia/validar2", params)
      .then(({ data }) => {
        this.context.commit(Mutations.CHECK_FAMILIAS_SUCCESS, data);
        return data;
      })
      .catch(({ response }) => {
        const error = response ? response.data.error : {};
        this.context.commit(Mutations.CHECK_FAMILIAS_FAILURE, error);
        throw error;
      });
  }
  @Action({ rawError: true })
  [Actions.POST_ACTIVIDAD](formData) {
    this.context.commit(Mutations.POST_ACTIVIDAD_PENDING);

    const params = {
      dni: formData.dni,
      datosSolicitud: { ...formData, libroFamilia: null },
      datosAdjuntos: {
        libroFamilia: formData.libroFamilia,
      },
      tipo: "SERVICIO_PROGRAMADO",
      version: 1,
      comarcaId: formData.comarca || formData.comarcaId,
    };

    return ApiService.post("peticion-formularios", params)
      .then(({ data }) => {
        this.context.commit(Mutations.POST_ACTIVIDAD_SUCCESS, data);
        return data.codigo;
      })
      .catch(({ response }) => {
        const error = response ? response.data.error : {};
        this.context.commit(Mutations.POST_ACTIVIDAD_FAILURE, error);
        throw error;
      });
  }

  @Mutation
  [Mutations.POST_PROFESIONALES_PENDING]() {
    this.pending = true;
  }

  @Mutation
  [Mutations.POST_PROFESIONALES_SUCCESS](data) {
    this.codigo = data.codigo;
    this.pending = false;
  }

  @Mutation
  [Mutations.POST_PROFESIONALES_FAILURE](errors) {
    this.errors = errors;
    this.pending = false;
  }

  @Action({ rawError: true })
  [Actions.POST_PROFESIONALES](formData) {
    this.context.commit(Mutations.POST_PROFESIONALES_PENDING);

    const params = {
      dni: formData.dni,
      datosSolicitud: {
        ...formData,
        disponibilidad: formData.disponibilidad.map((tiempo) => {
          return {
            dias: tiempo.dias.map((dia) => parseInt(dia, 10)),
            horas: tiempo.horas.map((hora) => parseInt(hora, 10)),
          };
        }),
        titulacionAdjunta: null,
        certificadoAntecedentes: null,
      },
      datosAdjuntos: {
        titulacionAdjunta: formData.titulacionAdjunta,
        certificadoAntecedentes: formData.certificadoAntecedentes,
      },
      tipo: "PROFESIONAL",
      version: 1,
      comarcaId: formData.comarca,
    };

    return ApiService.post("peticion-formularios", params)
      .then(({ data }) => {
        this.context.commit(Mutations.POST_PROFESIONALES_SUCCESS, data);
        return data.codigo;
      })
      .catch(({ response }) => {
        const error = response ? response.data.error : {};
        this.context.commit(Mutations.POST_PROFESIONALES_FAILURE, error);
        throw error;
      });
  }
}
