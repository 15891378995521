import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store/index";
import { BackendError } from "@/store/models";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { User, UserAuthInfo } from "./AuthState";

@Module({ namespaced: true, name: "AuthModule" })
export class AuthModule extends VuexModule implements UserAuthInfo {
  errors: BackendError | null = null;
  user = JSON.parse(JwtService.getUser() ?? "{}");
  isAuthenticated = !!JwtService.getToken();
  accessToken = JwtService.getToken() ?? "";

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setAuth(data) {
    this.isAuthenticated = true;
    this.user = data.usuario;
    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
    this.errors = null;
    ApiService.updateAccessToken(this.accessToken);
    JwtService.saveToken(this.accessToken, data.refreshToken, this.user);
  }

  @Mutation
  setUser(user) {
    this.user = user;
  }

  @Mutation
  setPassword(password) {
    this.user.password = password;
  }

  @Mutation
  [Actions.LOGOUT]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = null;
    JwtService.destroyToken();

    store.dispatch("FamiliasModule/familiaClear");
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = { ...credentials };

    return ApiService.post("/usuarios/login", params)
      .then(({ data }) => {
        this.setAuth(data);
      })
      .catch(({ response }) => {
        this.setError(response.data.error);
        throw response;
      });
  }

  @Action
  forgotPassword(payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/usuarios/reset", payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.setError(response.data.error);
          reject();
        });
    });
  }

  @Action
  changePassword(payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/usuarios/reset-password", payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.setError(response.data.error);
          reject();
        });
    });
  }

  @Action
  validateResetPasswordToken(payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/usuarios/reset-validate", payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.setError(response.data.error);
          reject();
        });
    });
  }

  @Action
  verifyAuth() {
    if (JwtService.getToken()) {
      let errorDetails;
      ApiService.get("/usuarios/verificarToken").catch(({ response }) => {
        if (response && response.data) {
          errorDetails = {
            message: response.data.error.message,
          };
        } else {
          errorDetails = {
            message: "Sesión expirada. Vuelva a introducir sus credenciales.",
          };
        }
        const authError = {
          error: errorDetails,
        };
        this.setError(authError);
        this.logout();
      });
    } else {
      this.logout();
    }
  }

  @Action
  refreshToken(data) {
    this.setAuth(data);
  }

  // @Action
  // updateUser(payload) {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.setUser(data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.setError(response.data.error);
  //         reject();
  //       });
  //   });
  // }
}
