import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BackendError, Inscripcion } from "../models";

@Module({ namespaced: true, name: "InscripcionModule" })
export default class InscripcionModule extends VuexModule {
  errors: BackendError | null = null;
  inscripcion: Inscripcion | null = null;
  isLoading = false;

  @Action
  crear({ inscripcion }: { inscripcion }) {
    const url = `/inscripciones`;

    return ApiService.post(url, inscripcion).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      }
    );
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setLoad(isLoading) {
    this.isLoading = isLoading;
  }
}
